<template>
<div class="user-index">
  <h2 class="content-title">绑定手机号</h2>
  <div class="content">
    <p style="color: #f56c6c; margin-bottom: 20px; font-size: 14px;">温馨提示：需要您绑定手机号，以便后续使用</p>
    <el-form style="width: 430px" label-position="right" label-width="100px" class="form">
      <el-form-item label="手机号：">
        <el-input v-model="mobile" placeholder="输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="验证码：">
        <el-input style="float:left;width:190px;margin-right:20px" clearable placeholder="请输入验证码" v-model="sms"></el-input>
        <el-button class="sms-btn" :disabled="!!smsCount" @click="sendSms" :type="smsCount ? '' : 'success'">
          {{ smsCount ? smsCount + "s后重试" : smsBtnText }}
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 120px;" type="primary" @click="submitForm" :loading="submitLoading" :disabled="canSubmit">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
export default {
  name: "inspect-index",
  data() {
    return {
      submitLoading: false,
      mobile: '',
      code: '',
      captcha: '',
      client: '',
      sms: "",
      smsCount: 0,
      smsBtnText: "发送验证码",
      smsTimer: null,
    };
  },
  mounted() {
  },
  computed: {
    canSubmit() {
      return !(this.mobile.length == 11 && this.sms)
    },
  },
  methods: {
    async submitForm() {
      let res = await this.$api({
        method: "post",
        url: "/api/bindphone",
        data: {
          phone: this.mobile,
          code: this.sms,
        }
      });
      if (res.code == 0) {
        this.$message.success('绑定成功');
        localStorage.setItem('is_bind_mobile', 1);
        this.$router.push('/')
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async sendSms() {
      if (this.mobile.length != 11) {
        this.$message('请输入手机号');
        return;
      }
      let res = await this.$api({
        method: "post",
        url: "/api/sendSmsByphone",
        data: {
          mobile: this.mobile,
        }
      });
      if (res.code == 0) {
        this.$message.success('发送成功');
        this.smsCount = 60;
        this.smsTimer = setInterval(() => {
          this.smsCount--;
          if (this.smsCount == 0) {
            clearInterval(this.smsTimer);
            this.smsBtnText = "重发验证码";
          }
        }, 1000);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }

    },
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.user-index {
  overflow: hidden;
  min-width: 960px;
  padding: 10px 4vw;

  .form-item {
    display: flex;
  }

  .sms-btn {
    width: 120px;
    flex: 0 0 120px;
    margin-left: 16px;
    display: flex;
    height: 42px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 4px;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }

  .content-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    margin: 20px auto 20px;
  }

  .content {
    margin: 0 auto;
    background: #fff;
    margin-top: 20px;
    min-height: calc(100vh - 220px);
    display: flex;
    padding: 30px 30px;
    flex-direction: column;
  }
}
</style>

<style lang="scss">
.user-index {
  background: #f3f6fb;

  .sms-btn .el-image {
    width: 120px;
  }

  .form-item {
    display: flex;
  }

  .el-form-item__content {
    text-align: left;
  }

  .el-form-item {
    margin-bottom: 32px;
  }
}
</style>
